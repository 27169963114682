import React, { useContext, useState, useEffect, useRef } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";
import { observer } from "mobx-react";
import { toJS } from "mobx"

import MainStoreContext from "../stores/MainStoreContext";

import ReactTags from 'react-tag-autocomplete'

import Linkify from 'react-linkify';
import regexifyString from 'regexify-string';

import {
    theme,
    ThemeProvider,
    CSSReset,
    Button,
    Box,
    Text,
    Textarea,
    Stack,
    Avatar,
    AvatarBadge,
    Flex,
    Grid,
    Heading,
    SimpleGrid,
    Divider,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightElement,
    Icon,
    IconButton,
    useToast,
    Tooltip,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Spinner,
    Link,
    Collapse,
    Checkbox,
    CheckboxGroup,
} from "@chakra-ui/core";

import moment from 'moment';

import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

import { FaUserPlus, FaUserSecret, FaUserNinja, FaUserTie, FaEdit } from 'react-icons/fa';

export default observer(function Profile() {

    const store = useContext(MainStoreContext);

    const allInputs = { imgUrl: '' };
    const [imageAsFile, setImageAsFile] = useState('');
    const [imageAsUrl, setImageAsUrl] = useState(allInputs);
    const inputFileRef = useRef(null);
    const formRef = useRef(null);

    const [userName, setUserName] = useState();
    const [dmName, setDMName] = useState();
    const [dciNum, setDCINum] = useState();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (store.user) {
            setUserName(store.user.display_name);
            setDMName(store.user.dm_name);
            setDCINum(store.user.dci_number);
            setLoading(false);
        }
    }, [store.logged, store.user]);

    const handleImageAsFile = (e) => {
        const image = e.target.files[0]
        setImageAsFile(imageFile => (image))
        setTimeout(() => formRef.current.dispatchEvent(new Event("submit")), 100);
    }

    const handleFireBaseUpload = e => {
        e.preventDefault()
        console.log('start of upload')
        // async magic goes here...
        if (imageAsFile === '') {
            console.log(`not an image, the image file is a ${typeof (imageAsFile)}`)
            return;
        }
        // const uploadTask = store.storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
        const uploadTask = store.storage.ref(`/images/p${store.user.id}`).put(imageAsFile)
        //initiates the firebase side uploading 
        uploadTask.on('state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it is happening
                // console.log("snap", snapShot)
            }, (err) => {
                //catches the errors
                console.log("err", err)
            }, () => {
                // gets the functions from storage refences the image storage in firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                store.storage.ref('images').child(`p${store.user.id}`).getDownloadURL()
                    .then(fireBaseUrl => {

                        console.log(fireBaseUrl);

                        store._updateProfile({ avatar: fireBaseUrl })

                        // setImageAsUrl(prevObject => ({ ...prevObject, imgUrl: fireBaseUrl }))
                    })
            })
    }

    return (
        <Box>
            {store.logged && store.userData && (
                <Stack p={4} alignItems="center" maxW={{ xs: "100%", md: "50%" }} m={'0 auto'}>
                    <Heading size={"lg"}>Profile</Heading>
                    <Box mb={10} />
                    <Avatar cursor="pointer" src={store.user.avatar ?? `https://robohash.org/${store.user.id}.png?set=set4`} size="xl" onClick={() => inputFileRef.current.click()} />
                    <Box mb={10} />
                    <InputGroup w={"100%"}>
                        <InputLeftAddon w={80} children="Name" />
                        <Input
                            onChange={event => setUserName(event.target.value)}
                            type="text" defaultValue={userName} placeholder="Your Name" />
                    </InputGroup>
                    {store.user.role.type === "dungeon_master" && (
                        <>
                            <InputGroup mb={2} w={"100%"}>
                                <InputLeftAddon w={80} children="DM" />
                                <Input
                                    onChange={event => setDMName(event.target.value)}
                                    type="text" defaultValue={dmName} placeholder="Awesome" />
                            </InputGroup>
                            <InputGroup mb={2} w={"100%"}>
                                <InputLeftAddon w={80} children="DCI" />
                                <Input
                                    onChange={event => setDCINum(event.target.value)}
                                    type="text" defaultValue={dciNum} placeholder="Your DCI Number" />
                            </InputGroup>
                        </>
                    )}
                    <form
                        ref={formRef}
                        onSubmit={handleFireBaseUpload}>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            ref={inputFileRef}
                            onChange={handleImageAsFile}
                        />

                    </form>
                    <Button
                        isLoading={loading}
                        onClick={() => {
                            setLoading(true);
                            store._updateProfile({
                                display_name: userName,
                                dm_name: dmName,
                                dci_number: dciNum
                            })
                        }}
                        variantColor="blue">Update Profile</Button>
                </Stack>
            )}
        </Box>
    )
});