// Firebase Config
const config = {
    apiKey: "AIzaSyBP4EHROWeSyacS7d5z0zNH10I9n8ZnZp0",
    authDomain: "tablefinder-4fe49.firebaseapp.com",
    databaseURL: "https://tablefinder-4fe49.firebaseio.com",
    projectId: "tablefinder-4fe49",
    storageBucket: "tablefinder-4fe49.appspot.com",
    messagingSenderId: "705399655438",
    appId: "1:705399655438:web:19e1c29405d189469f405f",
    measurementId: "G-KZHW2MFCHM"
};

export default config;