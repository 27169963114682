import { createContext } from 'react';
import { observable, computed, action, decorate, toJS } from "mobx"

import * as firebase from "firebase/app";
import "firebase/auth";
import 'firebase/storage';
import firebaseConfig from "../firebaseConfig";

import axios from 'axios';

import moment from 'moment';

import io from 'socket.io-client';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-97907344-2');
ReactGA.pageview(window.location.pathname + window.location.search);

export class MainStoreContext {
    // baseURL = "http://localhost:1337";
    baseURL = "https://table-finder.herokuapp.com";

    storage;

    userData;
    user;
    logged = false;

    allgames = [];
    weekFiltered = [];
    searchArray = [];
    epicArray = [];

    gameModalVisible = false;
    logsModalVisible = false;
    toEdit;
    addPlayersModalVisible = false;
    users = [];

    toastData;

    weekFilters = ["thisweek"];
    otherFilters = ["hidedone"];

    searchValue = "";

    constructor() {

        const that = this;

        firebase.initializeApp(firebaseConfig);
        this.storage = firebase.storage();

        firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.
                console.log("signed in?")
                const token = await firebase.auth().currentUser.getIdToken(true);
                that._getStrapiToken(user, token);
            } else {
                // No user is signed in.
                console.log("not signed in")
            }
        });

        const socket = io(this.baseURL);
        socket.on("update", data => {
            // console.log("UPDATE!", data);
            this._getAllGames();

            console.log("huh", data)

            if (this.logged) {

                if (this.user.role.type === "dungeon_master") {
                    this._getUsers();
                }

                if (data.action == "create") {
                    const dm = data.data.dungeon_master ? "DM " + data.data.dungeon_master.dm_name : "???"
                    const tier = data.data.tier;
                    const mod = data.data.module_name;

                    this.toastData = {
                        title: `${dm} opened a table!`,
                        description: `${mod} - ${tier}`,
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                        position: "bottom-right"
                    }
                }

            }
        });

        this._getAllGames();
    }

    _loginFacebook = () => {
        const that = this;
        const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

        ReactGA.event({
            category: 'User',
            action: 'Login Facebook'
        });

        firebase.auth().signInWithPopup(facebookAuthProvider).then(async function (result) {
            // firebase.auth().signInWithRedirect(facebookAuthProvider).then(async function (result) {
            const fbtoken = result.credential.accessToken;
            const user = result.user;
            const token = await firebase.auth().currentUser.getIdToken(true);

            ReactGA.event({
                category: 'User',
                action: 'Login Facebook Success'
            });

            // console.log(user, token)

            this._getStrapiToken(user, token);
        }).catch(function (err) {
            console.log(err)
            console.log(err.response);
            // that._showToast(
            //     "Error Logging in",
            //     JSON.stringify(err.response)
            // );
        });
    }

    _getStrapiToken = async (user, token) => {
        axios.get(`${this.baseURL}/connect/firebase?token=${token}`).then(response => {
            // console.log(response);
            this.userData = response.data;
            this.user = response.data.user;
            this.logged = true;
            this._getAllGames();
            if (this.user.role.type === "dungeon_master") {
                this._getUsers();
            }
            this._showToast(
                "Login success!",
                "",
                "success"
            );

        }).catch(err => {
            // console.log(err.response);
            //register if user does not exist
            if (err.response.data.data === "User does not exist.") {
                //register
                axios.post(`${this.baseURL}/auth/local/register`, {
                    username: user.displayName,
                    email: user.email,
                    token: token
                }).then(response => {
                    console.log("Registration success", response);
                    this.userData = response.data;
                    this.logged = true;
                    this._getAllGames();
                    if (this.user.role.type === "dungeon_master") {
                        this._getUsers();
                    }
                    this._showToast(
                        "Registration success!",
                        "",
                        "success"
                    );

                    ReactGA.event({
                        category: 'User',
                        action: 'Created an Account'
                    });

                }).catch(err => {
                    console.log(err.response);
                });
            } else {
                this._showToast(
                    "Error getting token",
                    JSON.stringify(err.response)
                );
            }
        })
    }

    _getEpic = async () => {
        const fetchData = async () => {
            let allData = [];
            let morePagesAvailable = true;
            let currentPage = 0;
            let limit = 100;

            const today = moment().subtract(1, "week").format("YYYY-MM-DD");
    
            while (morePagesAvailable) {
                let { data } = await axios.get(`${this.baseURL}/games?epic=true&_start=${currentPage}&_limit=${limit}`);
                data.forEach(e => allData.unshift(e));
                morePagesAvailable = data.length;
                currentPage += limit;
            }
    
            // console.log("page", currentPage);

            const sortedArray  = allData.sort((a,b) => moment(a.schedule) - moment(b.schedule))
    
            return sortedArray;
        }

        let games = await fetchData();
        this.epicArray = games;
    }

    _getAllGames = async () => {
        // const today = moment().format("YYYY-MM-DD");
        // const today = moment().day(1).format("YYYY-MM-DD");
        // const today = moment().day(-6).format("YYYY-MM-DD");
        // const today = moment().subtract(1, "week").format("YYYY-MM-DD");

        this._getEpic()

        const fetchData = async () => {
            let allData = [];
            let morePagesAvailable = true;
            let currentPage = 0;
            let limit = 100;

            const today = moment().subtract(1, "week").format("YYYY-MM-DD");
    
            while (morePagesAvailable) {
                let { data } = await axios.get(`${this.baseURL}/games?epic=false&_sort=schedule:ASC&schedule_gte=${today}&_start=${currentPage}&_limit=${limit}`);
                data.forEach(e => allData.unshift(e));
                morePagesAvailable = data.length;
                currentPage += limit;
            }
    
            // console.log("page", currentPage);

            const sortedArray  = allData.sort((a,b) => moment(a.schedule) - moment(b.schedule))
    
            return sortedArray;
        }

        let games = await fetchData();

        // console.log("data", games)

        this.allgames = games;
        

        // this.allgames = fetchData();

        // console.log(today);

        // const { data } = await axios.get(`${this.baseURL}/games?_sort=schedule:ASC&schedule_gte=${today}`);
        // const { data } = await axios.get(`${this.baseURL}/games?_sort=schedule:ASC`);
        // this.allgames = data;

        //okay we get all games then we need to filter them based on the weekfilter
        this._setWeekFilter(this.weekFilters);

        //then we search based on the search params


        // this._setWeekFilter(this.weekFilters);

        // this.searchArray = data;

        // this.searchArray = [];
        // this.searchArray = data;

        // console.log(data)
    }

    _searchAllGames(value) {
        this.searchValue = value;

        if (this.searchValue === "") {
            this.searchArray = this.weekFiltered;
            return;
        }

        const newData = [...this.weekFiltered].filter(item => {
            const schedule = moment(item.schedule).format("L");
            const dm = item.dungeon_master ? "dm " + item.dungeon_master.dm_name : "";
            const mod = item.module_name;
            const tier = item.tier;
            const players = item.players.map(p=>p.display_name)
            const itemData = `${schedule} ${dm} ${mod} ${mod} ${tier} ${players.toString()}`.toLowerCase();
            return itemData.indexOf(this.searchValue.toLowerCase()) > -1;
        });
        this.searchArray = newData;
    }

    _toggleGameModal(value, toEdit) {
        this.gameModalVisible = value;
        this.toEdit = toEdit;
    }

    _toggleLogsModal(value, toEdit) {
        this.logsModalVisible = value;
        this.toEdit = toEdit;
    }

    _toggleAddPlayersModal(value, toEdit) {
        this.addPlayersModalVisible = value;
        if (toEdit) this.toEdit = toEdit;
    }

    _postGame(params) {

        // console.log("SUBMIT", params);

        const id = params.id;
        delete params.id;
        params.dungeon_master = this.user.id;

        // console.log(params);

        axios({
            method: id ? 'put' : 'post',
            url: `${this.baseURL}/games` + (id ? `/${id}` : ''),
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
            data: params
        }).then(response => {
            this.gameModalVisible = false;

            if (id) {
                this._showToast(
                    "Game Updated!",
                    "",
                    "success"
                );
                ReactGA.event({
                    category: 'User',
                    action: 'Updated a Game'
                });
            } else {
                ReactGA.event({
                    category: 'User',
                    action: 'Created a Game'
                });
            }

            // this._showToast("Update", "A table was updated", "success");
            // console.log(response);
            // this._getAllGames();
        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                id ? "Error Updating" : "Error Posting",
                JSON.stringify(err.response)
            );
            this.gameModalVisible = false;
        });
    }

    _deletePost(id) {
        axios({
            method: 'delete',
            url: `${this.baseURL}/games/${id}`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            }
        }).then(response => {
            this.gameModalVisible = false;

            this._showToast(
                "Game Deleted",
                "",
                "warning"
            );

            ReactGA.event({
                category: 'User',
                action: 'Deleted a Game'
            });

            // console.log(response);
            // this._getAllGames();
        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Deleting",
                JSON.stringify(err.response)
            );
            this.gameModalVisible = false;
        });
    }

    _removePlayer(gameid, userid, index, playerlist) {

        axios({
            method: 'get',
            url: `${this.baseURL}/games/removeplayer/${gameid}/${userid}`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
            this._showToast(
                "Player removed",
                "",
                "warning"
            );

            ReactGA.event({
                category: 'User',
                action: 'Removed a Player'
            });

        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Removing Player",
                JSON.stringify(err.response)
            );
        });

        // playerlist.splice(index, 1);

        // const data = { players: [] };
        // for (let i = 0; i < playerlist.length; i++) {
        //     const p = playerlist[i];
        //     data.players.push(p.id);
        // }

        // axios({
        //     method: 'put',
        //     url: `${this.baseURL}/games/${gameid}`,
        //     headers: {
        //         Authorization: `Bearer ${this.userData.jwt}`,
        //     },
        //     data: data
        // }).then(response => {
        //     // console.log(response);

        //     this._showToast(
        //         "Player removed",
        //         "",
        //         "warning"
        //     );

        //     ReactGA.event({
        //         category: 'User',
        //         action: 'Removed a Player'
        //     });

        //     // this._getAllGames();
        // }).catch(err => {
        //     this._showToast(
        //         "Error Removing Player",
        //         JSON.stringify(err.response)
        //     );
        //     console.log('An error occurred:', err.response);
        // });
    }

    _addPlayers(gameid, playerlist) {
        
        const pl = playerlist.map(p=>p.id)

        axios({
            method: 'get',
            url: `${this.baseURL}/games/addplayers/${gameid}/[${pl.toString()}]`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
             // console.log(response);
            this.addPlayersModalVisible = false;

            this._showToast(
                "Player(s) added!",
                "",
                "success"
            );

            ReactGA.event({
                category: 'User',
                action: 'Added Players'
            });

        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Adding Player(s)",
                JSON.stringify(err.response)
            );
        });

        // const data = { players: [] };
        // for (let i = 0; i < playerlist.length; i++) {
        //     const p = playerlist[i];
        //     data.players.push(p.id);
        // }
        // axios({
        //     method: 'put',
        //     url: `${this.baseURL}/games/${gameid}`,
        //     headers: {
        //         Authorization: `Bearer ${this.userData.jwt}`,
        //     },
        //     data: data
        // }).then(response => {
        //     // console.log(response);
        //     this.addPlayersModalVisible = false;
        //     // this._getAllGames();

        //     this._showToast(
        //         "Player(s) added!",
        //         "",
        //         "success"
        //     );

        //     ReactGA.event({
        //         category: 'User',
        //         action: 'Added Players'
        //     });

        // }).catch(err => {
        //     console.log('An error occurred:', err.response);
        //     this._showToast(
        //         "Error Adding Player",
        //         JSON.stringify(err.response)
        //     );
        //     this.addPlayersModalVisible = false;
        // });
    }

    _getUsers() {
        axios({
            method: 'get',
            // url: `${this.baseURL}/users`,
            url: `${this.baseURL}/games/users/0`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
            // console.log(response);
            //process 
            const users = [];
            response.data.map((u, i) => {
                users.push({
                    id: u.id,
                    name: u.display_name,
                    dm_name: u.dm_name,
                    dci_number: u.dci_number
                })
            });

            this.users = users;
        }).catch(err => {
            this._showToast(
                "Error Getting Users",
                JSON.stringify(err.response)
            );
            console.log('An error occurred:', err.response);
        });
    }

    _joinReq(gameid, userid, waitlist) {

        axios({
            method: 'get',
            url: `${this.baseURL}/games/join/${gameid}/${userid}`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
            // console.log(response);

            this._showToast(
                "Join Request sent!",
                "",
                "success"
            );

            ReactGA.event({
                category: 'User',
                action: 'Joined a Game'
            });

        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Join Request",
                JSON.stringify(err.response)
            );
        });

        // const data = { waitlist: [] };
        // for (let i = 0; i < waitlist.length; i++) {
        //     const p = waitlist[i];
        //     data.waitlist.push(p.id);
        // }
        // data.waitlist.push(userid);
        // // console.log(data)

        // axios({
        //     method: 'put',
        //     url: `${this.baseURL}/games/${gameid}`,
        //     headers: {
        //         Authorization: `Bearer ${this.userData.jwt}`,
        //     },
        //     data: data
        // }).then(response => {
        //     // console.log(response);

        //     this._showToast(
        //         "Join Request sent!",
        //         "",
        //         "success"
        //     );

        //     ReactGA.event({
        //         category: 'User',
        //         action: 'Joined a Game'
        //     });

        //     // this._getAllGames();
        // }).catch(err => {
        //     console.log('An error occurred:', err.response);
        //     this._showToast(
        //         "Error Join Request",
        //         JSON.stringify(err.response)
        //     );
        //     // alert("Join Attempt " + JSON.stringify(err.response));
        // });
    }

    _joinCancel(gameid, userid, waitlist) {

        axios({
            method: 'get',
            url: `${this.baseURL}/games/canceljoin/${gameid}/${userid}`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
            this._showToast(
                "Join Request cancelled!",
                "",
                "warning"
            );

            ReactGA.event({
                category: 'User',
                action: 'Cancelled Join Request'
            });

        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Cancel Join",
                JSON.stringify(err.response)
            );
        });

        // const data = { waitlist: [] };
        // waitlist.map((u, i) => {
        //     if (u.id != userid) data.waitlist.push(u.id)
        // })

        // axios({
        //     method: 'put',
        //     url: `${this.baseURL}/games/${gameid}`,
        //     headers: {
        //         Authorization: `Bearer ${this.userData.jwt}`,
        //     },
        //     data: data
        // }).then(response => {
        //     // console.log(response);
        //     // this._getAllGames();

        //     this._showToast(
        //         "Join Request cancelled!",
        //         "",
        //         "warning"
        //     );

        //     ReactGA.event({
        //         category: 'User',
        //         action: 'Cancelled Join Request'
        //     });

        // }).catch(err => {
        //     console.log('An error occurred:', err.response);
        //     this._showToast(
        //         "Error Cancel Join",
        //         JSON.stringify(err.response)
        //     );
        // });
    }

    // store._approvePlayer(data.id, item.id, index, data.waitlist, data.players);
    // _approvePlayer(gameid, userid, waitlist, playerList) {
    _approvePlayer(gameid, userid) {

        axios({
            method: 'get',
            url: `${this.baseURL}/games/approveplayer/${gameid}/${userid}`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
        }).then(response => {
            // console.log(response);
            // this._getAllGames();

            this._showToast(
                "Player approved!",
                "",
                "success"
            );

            ReactGA.event({
                category: 'User',
                action: 'Approved Join Request'
            });

        }).catch(err => {
            console.log('An error occurred:', err.response);
            this._showToast(
                "Error Approving Player",
                JSON.stringify(err.response)
            );
        });

        // const data = { waitlist: [], players: [] };

        // playerList.map((p, i) => {
        //     data.players.push(p.id)
        // })

        // waitlist.map((u, i) => {
        //     if (u.id == userid) {
        //         data.players.push(u.id)
        //     } else {
        //         data.waitlist.push(u.id)
        //     }
        // })

        // axios({
        //     method: 'put',
        //     url: `${this.baseURL}/games/${gameid}`,
        //     headers: {
        //         Authorization: `Bearer ${this.userData.jwt}`,
        //     },
        //     data: data
        // }).then(response => {
        //     // console.log(response);
        //     // this._getAllGames();

        //     this._showToast(
        //         "Player approved!",
        //         "",
        //         "success"
        //     );

        //     ReactGA.event({
        //         category: 'User',
        //         action: 'Approved Join Request'
        //     });

        // }).catch(err => {
        //     console.log('An error occurred:', err.response);
        //     this._showToast(
        //         "Error Approving Player",
        //         JSON.stringify(err.response)
        //     );
        // });
    }

    _showToast(title, description, status = "error") {
        this.toastData = {
            title: title,
            description: description,
            status: status,
            duration: 9000,
            isClosable: true,
            position: "bottom-right"
        }
    }

    _setWeekFilter(value) {

        let final = [];

        this.weekFilters = value;

        ReactGA.event({
            category: 'User',
            action: 'Filtered Games',
            value: value.length
        });

        if (this.weekFilters.length == 3) {
            //show all
            final = [...this.allgames];
        } else {
            if (this.weekFilters.includes("lastweek")) {
                // console.log("add all from last week")
                final = final.concat([...this.allgames].filter(game => {
                    let d = moment(game.schedule)
                    return d <= moment().day(0)
                }));
            }
            if (this.weekFilters.includes("thisweek")) {
                // console.log("add all from this week")
                final = final.concat([...this.allgames].filter(game => {
                    let d = moment(game.schedule)
                    return d > moment().day(0) && d < moment().day(8)
                }));
            }
            if (this.weekFilters.includes("nextweek")) {
                // console.log("add all from next week")
                final = final.concat([...this.allgames].filter(game => {
                    let d = moment(game.schedule)
                    return d >= moment().day(8)
                }));
            }
        }

        if(this.otherFilters.includes("hidedone")){
            final = final.filter(game=>{
                let d = moment(game.schedule)
                return moment().diff(moment(game.schedule)) <= 0
            })
        }

        this.weekFiltered = final;

        this._searchAllGames(this.searchValue);
    }

    _setOtherFilter(value) {
        this.otherFilters = value;
        this._setWeekFilter(this.weekFilters)
    }

    _updateProfile(data) {
        axios({
            method: 'put',
            url: `${this.baseURL}/users/me`,
            headers: {
                Authorization: `Bearer ${this.userData.jwt}`,
            },
            data,
        }).then(response => {
            // console.log(response.data)
            this.user = response.data;
            this._showToast(
                "Profile Updated",
                "",
                "success"
            );
        }).catch(err => {
            this._showToast(
                "Error Updating Profile",
                JSON.stringify(err.response)
            );
            console.log('An error occurred:', err.response);
        });
    }

}

decorate(MainStoreContext, {
    userData: observable,
    user: observable,
    logged: observable,
    allgames: observable,
    searchArray: observable,
    gameModalVisible: observable,
    logsModalVisible: observable,
    toEdit: observable,
    addPlayersModalVisible: observable,
    users: observable,
    toastData: observable,
    searchValue: observable,
    weekFilters: observable,
    otherFilters: observable,
    _loginFacebook: action,
    _getStrapiToken: action,
    _getAllGames: action,
    _searchAllGames: action,
    _postGame: action,
    _deletePost: action,
    _toggleGameModal: action,
    _removePlayer: action,
    _toggleAddPlayersModal: action,
    _getUsers: action,
    _approvePlayer: action,
    _showToast: action,
    _setWeekFilter: action,
    _updateProfile: action,
    _setOtherFilter: action,
})

export default createContext(new MainStoreContext());
