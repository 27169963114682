import React, { useContext, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";
import { observer } from "mobx-react";
import { toJS } from "mobx"

import MainStoreContext from "../stores/MainStoreContext";

import ReactTags from 'react-tag-autocomplete'

import Linkify from 'react-linkify';
import regexifyString from 'regexify-string';

import {
    theme,
    ThemeProvider,
    CSSReset,
    Button,
    Box,
    Text,
    Textarea,
    Stack,
    Avatar,
    Flex,
    Grid,
    Heading,
    SimpleGrid,
    Divider,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Icon,
    IconButton,
    useToast,
    Tooltip,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Spinner,
    Link,
    Collapse,
    Checkbox,
    CheckboxGroup
} from "@chakra-ui/core";

import moment from 'moment';

import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

import { FaUserPlus, FaUserSecret, FaUserNinja, FaUserTie } from 'react-icons/fa';

// function GameCard({ data, ...rest }) {
//     return (
//         <Stack spacing={1}>
//             <Box p={4} shadow="md" borderWidth="1px" textAlign="center">
//                 <Heading fontSize="xl">{`${data.module_name} - ${data.tier}`}</Heading>
//                 <Divider />
//                 <Heading fontSize="md">{"DM " + data.dungeon_master.dm_name}</Heading>
//                 <Divider />
//                 <Text mt={1}>{data.location}</Text>
//                 <Text mt={1}>{moment(data.schedule).format('MMMM Do YYYY, h:mm:ss a')}</Text>
//                 <Divider />
//                 <Heading fontSize="md">{"Players: " + data.players.length + "/" + data.slots}</Heading>
//                 {data.players.map((item, i) => {
//                     return <Text key={i} mt={1}>{item.username + " " + (item.dm_name ? `(DM ${item.dm_name})` : "")}</Text>
//                 })}
//                 <Divider />
//                 <Text mt={1}>{data.status}</Text>
//                 <Divider />
//                 <Text mt={1}>{data.notes}</Text>
//                 <Divider />
//                 <Button
//                     variantColor="blue"
//                     variant="solid"
//                 >Request to Join</Button>
//             </Box>
//         </Stack>
//     );
// }

function GameCard({ data, ...rest }) {

    const store = useContext(MainStoreContext);
    const [loading, setLoading] = useState(false);
    const [joined, setJoined] = useState(false);
    const [waitlisted, setWaitlisted] = useState(false);
    const [showPlayers, setShowPlayers] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showLogs, setShowLogs] = useState(false);

    const [isPast, setIsPast] = useState(false);
    const [dmReserved, setDmReserved] = useState([]);

    useEffect(() => {
        setLoading(false);

        //check if player is already on the list
        if (store.userData) {
            const hasJoin = data.players.some(
                value => { return value.id === store.user.id });
            setJoined(hasJoin);

            const hasWaitlist = data.waitlist.some(
                value => { return value.id === store.user.id });
            setWaitlisted(hasWaitlist);
        }

        if (data.dm_reserved && data.dm_reserved > 0) {
            const r = [];
            for (let i = 0; i < data.dm_reserved; i++) {
                r.push(0);
            }
            setDmReserved(r);
            // console.log(dmReserved)
        }

        setIsPast(moment().diff(moment(data.schedule)) > 0 ? true : false)

    }, [store.searchArray])

    return (
        <Stack spacing={1}>
            <Box position="relative" p={4} boxShadow="xl" borderRadius={10} borderWidth={4} textAlign="center"
                bg={"white"}
                opacity={isPast ? 0.75 : 1}
                borderColor={
                    data.tier.indexOf("T1") >= 0 ? "cyan.300" : "" ||
                        data.tier.indexOf("T2") >= 0 ? "green.500" : "" ||
                            data.tier.indexOf("T3") >= 0 ? "purple.300" : "" ||
                                data.tier.indexOf("T4") >= 0 ? "red.500" : "" ||
                                    data.tier.toLowerCase().indexOf("homebrew") >= 0 ? "yellow.300" : "" ||
                                        data.tier.toLowerCase().indexOf("hardcover") >= 0 ? "orange.300" : ""
                }
            >
                {/* <Text textAlign="right" position="absolute" fontSize={11} top={0} right={1}>{`Game #${data.id}`}</Text>
                <Text textAlign="left" position="absolute" fontSize={11} top={0} left={1}>{
                    `Posted: ${moment(data.created_at).local().fromNow()}`
                }</Text>
                <Text textAlign="left" position="absolute" fontSize={11} top={3} left={1}>{
                    `Updated: ${moment(data.updated_at).local().fromNow()}`
                }</Text> */}

                <Stack position="absolute" top={0} left={0} w={"100%"} pl={1} pr={1} isInline justifyContent="space-between" alignItems="center">
                    <Text fontSize={11}>{`Posted: ${moment(data.created_at).local().fromNow()}`}</Text>
                    <Text fontSize={11}>{`Updated: ${moment(data.updated_at).local().fromNow()}`}</Text>
                    <Text fontSize={11}>{`Game #${data.id}`}</Text>
                </Stack>

                {/* <Divider /> */}
                <Stack isInline justifyContent="space-between">
                    <Text mt={1}>{moment(data.schedule).format("MM/DD/YYYY h:mmA ddd")}</Text>
                    {/* <Text mt={1}>{moment(data.schedule).format('llll')}</Text> */}
                    <Text fontWeight="bold" color={isPast ? "gray.500" : "orange.500"} mt={1}>{moment(data.schedule).local().fromNow()}</Text>
                </Stack>
                {/* <Stack isInline justifyContent="space-between">
                    <Text mt={1}>{moment(data.schedule).format('L')}</Text>
                    <Text mt={1}>{moment(data.schedule).format('LT')}</Text>
                </Stack> */}
                <Divider />
                <Stack isInline justifyContent="space-between">
                    <Text>{data.location}</Text>
                    <Text>{data.tier}</Text>
                </Stack>
                <Divider />
                <Heading fontSize="md">{data.module_name}</Heading>
                <Divider />
                <Stack isInline justifyContent={data.dungeon_master ? "space-between" : "center"} alignItems="center">
                    {data.dungeon_master ? (
                        <>
                            <Stack isInline alignItems="center">
                                <Avatar size="sm" name={data.dungeon_master.username} src={`https://robohash.org/${data.dungeon_master.id}.png?set=set4`} />
                                {/* <Avatar size="md" src={data.dungeon_master.avatar ?? `https://robohash.org/${data.dungeon_master.id}.png?set=set4`} /> */}
                                <Heading fontSize="sm">{"DM " + data.dungeon_master.dm_name + (store.logged && data.dungeon_master.id === store.user.id ? " (You)" : "")}</Heading>
                            </Stack>
                            {/* <Heading fontSize="sm">{"DCI " + (data.dungeon_master.dci_number ?? 'N/A')}</Heading> */}
                            <Heading fontSize="sm">
                                {data.dungeon_master.dci_number ?
                                    (data.dungeon_master.dci_number === "" ? "DCI N/A" : `DCI ${data.dungeon_master.dci_number}`) :
                                    "DCI N/A"
                                }
                            </Heading>
                        </>
                    ) : (
                            <Heading textAlign fontSize="sm">Dungeon Master TBA</Heading>
                        )}

                </Stack>
                <Divider />

                <Stack isInline alignItems="center" justifyContent="center"
                // onClick={() => setShowPlayers(!showPlayers)}
                // cursor="pointer"
                >
                    <Heading fontSize="md">{"Players: " + (data.players.length + (data.dm_reserved ?? 0)) + "/" + data.slots}</Heading>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Show players"
                        icon={showPlayers ? "chevron-up" : "chevron-down"}
                        onClick={() => setShowPlayers(!showPlayers)} />
                    {data.dungeon_master && store.logged && data.dungeon_master.id === store.user.id && (
                        <Tooltip ml={2} hasArrow label="More the merrier!" placement="bottom" bg="blue.500">
                            <IconButton size="sm" variantColor="blue" aria-label="Add players" icon={FaUserPlus}
                                onClick={() => store._toggleAddPlayersModal(true, data)}
                            />
                        </Tooltip>
                    )}

                </Stack>

                <Collapse mt={2} isOpen={showPlayers}>
                    <Stack alignItems="flex-start">
                        {data.players.map((item, i) => {
                            return (
                                <Stack key={i} isInline width="100%" alignItems="center" justifyContent="space-between">
                                    <Stack isInline alignItems="flex-start">
                                        <Avatar size="sm" name={item.username} src={`https://robohash.org/${item.id}.png?set=set4`} />
                                        {/* <Avatar size="sm" as={FaUserNinja} /> */}
                                        {/* <Avatar size="sm" src={item.avatar ?? `https://robohash.org/${item.id}.png?set=set4`} /> */}
                                        <Text mt={1} color={data.dungeon_master && store.logged && data.dungeon_master.id === store.user.id ? "(You)" : ""}>
                                            {/* {item.username + " " + (item.dm_name ? `(DM ${item.dm_name})` : "")} */}
                                            {item.display_name}
                                        </Text>
                                    </Stack>
                                    {((data.dungeon_master && store.logged && data.dungeon_master.id === store.user.id) || (joined && item.id == store.user.id)) && (
                                        // {(store.logged && data.dungeon_master.id === store.userData.user.id) && (
                                        <Tooltip hasArrow label="Please don't crit me!" placement="bottom" bg="blue.500">
                                            <IconButton isLoading={loading} size="xs" variantColor="red" aria-label="Remove player" icon='minus'
                                                onClick={() => {
                                                    setLoading(true);
                                                    store._removePlayer(data.id, item.id, i, data.players)
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Stack>
                            )
                        })}
                    </Stack>
                    {data.dm_reserved > 0 && (
                        <Stack mt={2} alignItems="flex-start">
                            {dmReserved.map((item, i) => {
                                return (
                                    <Stack key={i} isInline width="100%" alignItems="center" justifyContent="flex-start">
                                        <Avatar size="sm" as={FaUserSecret} />
                                        <Text>DM Reserved</Text>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    )}
                </Collapse>

                {data.dungeon_master && store.logged && data.dungeon_master.id === store.user.id && (
                    <>
                        <Divider />
                        <Heading fontSize="md">{"Waitlist: (" + data.waitlist.length + ")"}</Heading>
                        <Stack alignItems="flex-start">
                            {data.waitlist.map((item, i) => {
                                return (
                                    <Stack key={i} isInline width="100%" alignItems="center" justifyContent="space-between">
                                        <Stack isInline alignItems="flex-start">
                                            <Avatar size="sm" name={item.username} src={`https://robohash.org/${item.id}.png?set=set4`} />
                                            {/* <Avatar size="sm" src={item.avatar ?? `https://robohash.org/${item.id}.png?set=set4`} /> */}
                                            <Text mt={1} color={store.logged && data.dungeon_master.id === store.user.id ? "(You)" : ""}>
                                                {/* {item.username + " " + (item.dm_name ? `(DM ${item.dm_name})` : "")} */}
                                                {item.display_name}
                                            </Text>
                                        </Stack>
                                        {((store.logged && data.dungeon_master.id === store.user.id)) && (
                                            // {(store.logged && data.dungeon_master.id === store.userData.user.id) && (
                                            <Tooltip hasArrow label="Woot!" placement="bottom" bg="blue.500">
                                                <IconButton isLoading={loading} size="xs" variantColor="green" aria-label="Remove player" icon='check'
                                                    onClick={() => {
                                                        setLoading(true);
                                                        store._approvePlayer(data.id, item.id, data.waitlist, data.players);
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Stack>

                                )
                            })}
                        </Stack>
                    </>
                )}


                <Divider />

                {/* <Text mt={1}
                    fontWeight="bold"
                    color={
                        data.status === "Scheduled" ? "green.500" : "" ||
                            // data.status === "Done" ? "red.500" : "" ||
                            data.status === "Cancelled" ? "red.500" : "" ||
                                data.status === "Rescheduled" ? "orange.500" : ""
                    }
                >{data.status}</Text> */}

                {isPast ? (
                    <Text mt={1}
                        fontWeight="bold"
                    >{'DONE'}</Text>
                ) : (
                        <Text mt={1}
                            fontWeight="bold"
                            color={
                                data.status === "Scheduled" ? "green.500" : "" ||
                                    // data.status === "Done" ? "red.500" : "" ||
                                    data.status === "Cancelled" ? "red.500" : "" ||
                                        data.status === "Rescheduled" ? "orange.500" : ""
                            }
                        >{data.status}</Text>
                    )}

                {data.notes && (
                    <>
                        <Divider />
                        <Button
                            size="sm"
                            rightIcon={showNotes ? "chevron-up" : "chevron-down"}
                            onClick={() => setShowNotes(!showNotes)}
                        >{showNotes ? "Hide Notes" : "Show Notes"}</Button>


                        <Collapse mt={2} isOpen={showNotes}>
                            <Flex textAlign="left" whiteSpace="pre-wrap">

                                <Text>{
                                    regexifyString({
                                        pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                                        decorator: (match, index) => {
                                            // return `<${match}>`;
                                            return <Link key={index} href={match} isExternal>{match}</Link>
                                        },
                                        input: data.notes,
                                    })}
                                </Text>
                            </Flex>
                        </Collapse>
                    </>
                )}

                {!isPast && store.logged && (!joined && !waitlisted) && data.dungeon_master && data.dungeon_master.id !== store.user.id && (
                    <>
                        <Divider />
                        <Tooltip hasArrow label="Let DM know!" placement="bottom" bg="blue.500">
                            <Button
                                variantColor="blue"
                                variant="solid"
                                onClick={() => {
                                    // alert("Oops! That's a Nat 1! Just kidding, feature coming soon!")
                                    store._joinReq(data.id, store.user.id, data.waitlist);
                                }}
                            >Request to Join</Button>
                        </Tooltip>
                    </>
                )}

                {waitlisted && (
                    <>
                        <Divider />
                        <Stack isInline justifyContent="center" alignItems="center">
                            {/* <Text>You have requested to Join</Text> */}
                            <Button
                                isDisabled
                                variantColor="blue"
                                variant="solid"
                            >You have requested to Join</Button>
                            <Tooltip hasArrow label="Cancel Join Request" placement="bottom" bg="red.500">
                                <IconButton isLoading={loading} size="xs" variantColor="red" aria-label="Cancel request" icon='minus'
                                    onClick={() => {
                                        setLoading(true);
                                        store._joinCancel(data.id, store.user.id, data.waitlist)
                                    }}
                                />
                            </Tooltip>

                        </Stack>

                    </>
                )}

                {data.dungeon_master && store.logged && data.dungeon_master.id === store.user.id && store.user.role.type === "dungeon_master" && (
                    <>
                        <Divider />
                        <Stack isInline spacing={2} justifyContent="space-evenly">
                            <Tooltip hasArrow label="Click here to edit" placement="bottom" bg="blue.500">
                                <Button
                                    variantColor="gray"
                                    variant="solid"
                                    onClick={() => {
                                        store._toggleGameModal(true, data)
                                    }}
                                >Edit Game</Button>
                            </Tooltip>
                            <Tooltip hasArrow label="See Game Activity" placement="bottom" bg="blue.500">
                                <Button
                                    variantColor="blue"
                                    variant="outline"
                                    onClick={() => {
                                        store._toggleLogsModal(true, data)
                                    }}
                                >View Logs</Button>
                            </Tooltip>
                        </Stack>

                    </>
                )}

            </Box>
        </Stack >
    );
}

function GameModal({ isOpen, toEdit }) {

    const store = useContext(MainStoreContext);

    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [mod, setMod] = useState('');
    const [tier, setTier] = useState('');
    const [slots, setSlots] = useState(5);
    const [dm_reserved, setDmReserved] = useState(0);
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('Scheduled');
    const [mode, setMode] = useState("Create");

    const [isAlertOpen, setIsOpen] = useState();
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMode("Create Game");

        if (isOpen && toEdit) {
            const data = toJS(toEdit);
            setDate(data.schedule ?? "");
            setLocation(data.location);
            setMod(data.module_name);
            setTier(data.tier);
            setSlots(data.slots);
            setDmReserved(data.dm_reserved);
            setNotes(data.notes);
            setStatus(data.status);
            setMode("Edit Game");
        } else if (!isOpen) {
            //clear data
            // console.log("CLEAR DATA");
            setDate("")
            setLocation("")
            setMod("")
            setTier("")
            setSlots(5)
            setDmReserved(0);
            setStatus("Scheduled")
            setLoading(false);
        }

    }, [isOpen]);

    return (
        <>
            <Modal preserveScrollBarGap size="xl" isOpen={isOpen} onClose={() => {
                store._toggleGameModal(false)
            }}>
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={e => {
                        // console.log(date, moment(date).toISOString());
                        e.preventDefault();

                        setLoading(true);

                        store._postGame(
                            {
                                id: toEdit ? toEdit.id : null,
                                schedule: Array.isArray(date) ? date[0] : moment(date).toISOString(),
                                location,
                                module_name: mod,
                                tier,
                                slots,
                                dm_reserved,
                                status,
                                notes
                            }
                        )
                        return true;
                    }}>
                        <ModalHeader>{mode}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing={3}>

                                <Text>Date</Text>
                                <Flatpickr
                                    data-enable-time
                                    options={{
                                        allowInput: true,
                                        dateFormat: "Y/m/d G:i K"
                                    }}
                                    onChange={d => {
                                        setDate(d);
                                    }}
                                    render={
                                        ({ defaultValue, value, ...props }, ref) => {
                                            // return <CustomInput defaultValue={defaultValue} inputRef={ref} />
                                            return <Input isRequired placeholder="Select Date" {...props} defaultValue={date} ref={ref} />
                                        }
                                    }
                                />
                                <Text>Location</Text>
                                <Input isRequired value={location ?? ''} onChange={event => setLocation(event.target.value)} placeholder="e.g. Discord/Roll20, FoundryVTT, etc." size="md" />
                                <Text>Module Name</Text>
                                <Input isRequired value={mod ?? ''} onChange={event => setMod(event.target.value)} placeholder="DDEX1-1 Defiance in Phlan" size="md" />
                                <Select isRequired value={tier ?? ''} onChange={event => setTier(event.target.value)} placeholder="Select Tier">
                                    <option value="T1 (L1-L2)">T1 (L1-L2)</option>
                                    <option value="T1 (L1-L4)">T1 (L1-L4)</option>
                                    <option value="T2 (L5-L10)">T2 (L5-L10)</option>
                                    <option value="T3 (L11-L16)">T3 (L11-L16)</option>
                                    <option value="T4 (L17-L20)">T4 (L17-L20)</option>
                                    <option value="HOMEBREW">HOMEBREW</option>
                                    <option value="HARDCOVER">HARDCOVER</option>
                                    <option value="T1 HARDCOVER">T1 HARDCOVER</option>
                                    <option value="T2 HARDCOVER">T2 HARDCOVER</option>
                                    <option value="T3 HARDCOVER">T3 HARDCOVER</option>
                                    <option value="T4 HARDCOVER">T4 HARDCOVER</option>
                                    <option value="Tier TBA">Tier TBA</option>
                                </Select>
                                <Stack isInline>
                                    <Box w="100%">
                                        <Text>Slots</Text>
                                        <NumberInput isRequired defaultValue={slots ?? ''} onChange={value => setSlots(value)}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                    <Box w="100%">
                                        <Text>DM Reserved</Text>
                                        <NumberInput isRequired defaultValue={dm_reserved ?? ''} onChange={value => setDmReserved(value)}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                </Stack>
                                <Text>Notes</Text>
                                {/* <Input value={notes ?? ''} onChange={event => setNotes(event.target.value)} placeholder="Notes here" size="md" /> */}
                                <Textarea
                                    value={notes ?? ''}
                                    onChange={event => setNotes(event.target.value)}
                                    placeholder="Notes here (links, descriptions, etc.)"
                                    size="md"
                                    resize={"None"}
                                />
                                <Select isRequired value={status ?? ''} onChange={event => setStatus(event.target.value)}>
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Done">Done</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Rescheduled">Rescheduled</option>
                                </Select>
                            </Stack>
                        </ModalBody>
                        <ModalFooter justifyContent={toEdit ? "space-between" : "flex-end"}>
                            {toEdit && (
                                <Button
                                    isLoading={loading}
                                    loadingText={"Deleting"}
                                    variantColor="red" mr={3} onClick={() => setIsOpen(true)}>
                                    Delete
                                </Button>
                            )}
                            {/* <Button variant="ghost" mr={3} onClick={() => { store._toggleGameModal(false) }}>
                        Cancel
                        </Button> */}
                            <Button
                                isLoading={loading}
                                loadingText={toEdit ? "Updating" : "Submitting"}
                                variant="outline"
                                type="submit" onClick={() => {

                                }} variantColor="green">{toEdit ? "Update" : "Submit"}</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal >
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Game
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            variantColor="red" onClick={() => {
                                onClose();
                                setLoading(true);
                                store._deletePost(toEdit.id)
                            }} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

function AddPlayersModal({ isOpen, toEdit }) {

    const store = useContext(MainStoreContext);

    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            //get existing users
            const existing = [];
            toEdit.players.map((p, i) => {
                // existing.push({ id: p.id, name: p.username });
                existing.push({ id: p.id, name: p.display_name });
            })
            if (existing.length > 0) {
                const sugg = [...store.users].filter(array => existing.some(filter => filter.id !== array.id));
                const filterDM = sugg.filter(x => x.id !== toEdit.dungeon_master.id);
                setTags(existing);
                setSuggestions(filterDM);
            } else {
                setTags([]);
                const filterDM = [...store.users].filter(x => x.id !== toEdit.dungeon_master.id);
                setSuggestions(filterDM);
            }
        } else {
            setTags([]);
            setLoading(false)
        }
    }, [store.users, isOpen]);

    const onDelete = (i) => {
        if (i === -1) return false;
        const temp = tags.slice(0);
        temp.splice(i, 1);
        const removedTag = tags[i];
        const sugg = [].concat(suggestions, removedTag);
        setTags(temp);
        setSuggestions(sugg);
    }

    const onAddition = (tag) => {
        const temp = [].concat(tags, tag);
        const sugg = suggestions.filter(x => x.id !== tag.id);
        setTags(temp);
        setSuggestions(sugg);
    }

    return (
        <>
            <Modal preserveScrollBarGap size="xl" isOpen={isOpen} onClose={() => {
                store._toggleAddPlayersModal(false)
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Players</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={2}>Enter the name of the players you wish to add to your game</Text>
                        <ReactTags
                            placeholderText={"Type in names of players"}
                            tags={tags}
                            suggestions={suggestions}
                            onDelete={value => onDelete(value)}
                            onAddition={value => onAddition(value)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={loading}
                            loadingText={"Updating"}
                            // isLoading
                            // loadingText="Submitting"
                            // variantColor="teal"
                            // variant="outline"

                            variantColor="blue" mr={3} onClick={() => {
                                if (tags.length > 0) {
                                    setLoading(true);
                                    store._addPlayers(toEdit.id, tags);
                                } else {
                                    store._toggleAddPlayersModal(false)
                                }
                            }}>
                            Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const ViewLogs = ({isOpen, toEdit}) => {

    const store = useContext(MainStoreContext)

    return (
        <Modal preserveScrollBarGap size="xl" isOpen={isOpen} onClose={() => {
            store._toggleLogsModal(false)
        }}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{`Game #${toEdit ? toEdit.id : ""} Logs`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack p={2}>
                    <Text>{toEdit && !toEdit.logs && "Logs not available"}</Text>
                    {toEdit && toEdit.logs && toEdit.logs.map((l,i)=>{
                        let msg = "";
                        switch(l.action){
                            case "join_request":
                                msg = "requested to join"
                                break;
                            case "cancelled_join_request":
                                msg = "cancelled join request"
                                break;
                        }
                        return (
                            <>
                            <Stack key={i.toString()} isInline>
                                <Text fontWeight="bold" fontSize={14}>{`${l.player}`}</Text>
                                <Text fontSize={14}>{`${msg} at ${l.date}`}</Text>
                            </Stack>
                            
                            <Divider />
                            </>
                        )
                    })}
                </Stack>
            </ModalBody>
            <ModalFooter>
                <Button onClick={()=>store._toggleLogsModal(false)}>Close</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default observer(function Board() {

    const store = useContext(MainStoreContext);

    useEffect(() => {
        // store._setWeekFilter(["thisweek"])
    }, []);

    return (
        <>
            <Box p={{ sm: 1, md: 4 }} bg={"#f2e7d3"}>
                {store.logged && store.user.role.type === "dungeon_master" && (
                    <Stack display="none" p={1} isInline mb={1} alignItems="center">
                        <Text>
                            {
                                `${store.logged ? `Hello, DM ${store.user.dm_name}` ?? "" : ""}`
                            }
                        </Text>
                        <Tooltip hasArrow label="Bring joy to others!" placement="top" bg="blue.500">
                            <Button
                                variantColor="blue"
                                variant="solid"
                                onClick={() => {
                                    store._toggleGameModal(true)
                                }}
                            >New Game</Button>
                        </Tooltip>
                    </Stack>
                )}
                <InputGroup p={{ xs: 0 }} size="lg"
                    // mb={{ xs: -1, md: 2 }}
                    mb={1}
                    display="none"
                >
                    <InputLeftElement children={<Icon name="search" color="gray.300" />} />
                    <Input value={store.searchValue} type="text" placeholder="Search Anything (eg. Tier, DM Name, Module Name, Date, etc.)" onChange={
                        event => store._searchAllGames(event.target.value)
                    } />
                    <InputRightElement width="4.5rem">
                        {store.searchValue !== "" && (
                            <IconButton
                                size="sm"
                                variant="ghost"
                                aria-label="Clear search"
                                icon="close"
                                onClick={() => store._searchAllGames("")} />
                        )}
                    </InputRightElement>
                </InputGroup>

                <Stack display="none" isInline justifyContent="center" borderRadius={2}
                    mb={1}
                >
                    <CheckboxGroup
                        bg="white"
                        p={2}
                        isInline
                        spacing={5}
                        variantColor="blue"
                        defaultValue={store.weekFilters}
                        onChange={value => store._setWeekFilter(value)}
                    >
                        <Checkbox value="lastweek">Last Week</Checkbox>
                        <Checkbox value="thisweek">This Week</Checkbox>
                        <Checkbox value="nextweek">Beyond</Checkbox>
                    </CheckboxGroup>
                    <CheckboxGroup
                        bg="white"
                        ml={2}
                        p={2}
                        isInline
                        spacing={5}
                        variantColor="blue"
                        defaultValue={store.otherFilters}
                        onChange={value => store._setOtherFilter(value)}
                    >
                        <Checkbox value="hidedone">Hide Done</Checkbox>
                        {/* <Checkbox value="thisweek">This Week</Checkbox> */}
                        {/* <Checkbox value="nextweek">Beyond</Checkbox> */}
                    </CheckboxGroup>
                </Stack>

                {store.allgames.length <= 0 && (
                    <Flex justifyContent="center" alignItems="center">
                        <Spinner size="xl" />
                    </Flex>
                )}

                {store.allgames.length > 0 && store.searchArray.length <= 0 && (
                    <Flex justifyContent="center" alignItems="center">
                        <Text>No results</Text>
                    </Flex>
                )}

                <SimpleGrid p={{ xs: 2 }} columns={{ xs: 1, md: 2, lg: 4 }} spacing={2}>
                    {store.epicArray.map((item, i) => {
                        return <GameCard key={i} data={item} />
                    })}
                </SimpleGrid>
                <GameModal isOpen={store.gameModalVisible} toEdit={store.toEdit} />
                <AddPlayersModal isOpen={store.addPlayersModalVisible} toEdit={store.toEdit} />
                <ViewLogs isOpen={store.logsModalVisible} toEdit={store.toEdit} />
            </Box>
        </>
    )
})