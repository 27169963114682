import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { observer } from "mobx-react";

// import * as firebase from "firebase/app";
// import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
} from "@react-firebase/auth";
import firebaseConfig from "./firebaseConfig";

import MainStoreContext from "./stores/MainStoreContext";

import {
  FaFacebookSquare
} from 'react-icons/fa';

import {
  theme,
  ThemeProvider,
  CSSReset,
  Button,
  Box,
  Text,
  Stack,
  Avatar,
  Flex,
  Tooltip,
  Icon,
  Image,
  useToast,
} from "@chakra-ui/core";

import Header from "./components/Header";
import Board from "./containers/Board";
import Profile from "./containers/Profile";
import Epic from "./containers/Epic";

const breakpoints = ["320px", "320px", "768px", "1024px", "1440px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const newTheme = {
  ...theme,
  breakpoints
};

function ToastExample(props) {
  const toast = useToast();

  useEffect(() => {
    if (props.data) toast(props.data)
  }, [props.data])

  return <></>
}

export default observer(function App() {

  const store = useContext(MainStoreContext);
  const history = useHistory();

  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const [toastData, setToastData] = useState()

  useEffect(() => {

    setToastData(store.toastData)

  }, [store.toastData]);

  return (
    <ThemeProvider theme={newTheme}>
      <ToastExample data={toastData} />
      {/* <FirebaseAuthProvider {...firebaseConfig} firebase={store.fb}>
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            // console.log(isSignedIn, user)
            if (isSignedIn && !store.logged) {
              store._getStrapiToken(user.providerData[0], user.xa);
              // setLoading(true);
            } else {
              // setLoading(false);
            }
          }}
        </FirebaseAuthConsumer> */}
      <Router>
        <CSSReset />
        <Header
          leftIcon={
            <Image
              size="50px"
              objectFit="cover"
              src="/fort7_logo_white_small.png"
              alt=""
            />
            // <Text fontWeight="bold">Table Finder</Text>
          }
          rightIcon={
            <Stack height="2.5rem" visibility={!show ? "hidden" : "visible"} isInline alignItems="center">

              <Tooltip hasArrow label="Comments, suggestions? Please let me know!" placement="bottom" bg="blue.500">
                <Button mr={4}
                  variantColor="orange"
                  variant="solid"
                  onClick={() => {
                    window.open("https://forms.gle/A64Q6Xvrvdu9AwVs5", "_blank");
                  }}>
                  Submit Feedback
                </Button>
              </Tooltip>


              {!store.logged ? (
                <Button
                  // display={!show ? "none" : "flex"} 
                  isLoading={loading}
                  // loadingText={"Deleting"}
                  leftIcon={FaFacebookSquare}
                  variantColor="blue"
                  variant="solid" onClick={() => {
                    setLoading(true)
                    store._loginFacebook()
                  }}>
                  Login
                </Button>
              ) : (
                  <Link to="/profile">
                    <Flex cursor="pointer" alignItems="center" justifyContent="space-between"
                      onClick={() => {
                        // alert("Profile coming soon!");
                      }}>
                      <Text>{`Hello, ${store.user.display_name ?? 'Adventurer!'}`}</Text>
                      <Avatar
                        // border={"1px solid gray"}
                        size="sm" ml={2} name={store.user.display_name} src={store.user.avatar ?? `https://robohash.org/${store.user.id}.png?set=set4`} />
                    </Flex>
                  </Link>
                )}

            </Stack>
          }
        >
          <Link to="/">Adventure Board</Link>
          {/* <Link to="/about">About</Link> */}
          {/* <Link to="/users">Users</Link> */}
        </Header>

        <Switch>
          <Route path="/epic">
            <Epic />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Board />
          </Route>
        </Switch>
      </Router >
      {/* </FirebaseAuthProvider > */}
    </ThemeProvider >
  );
})

function About() {
  return (
    <Box p={{ sm: 1, md: 4 }} >
      <Text>About Coming Soon!</Text>
    </Box>
  )
}

function Users() {
  return (
    <Box p={{ sm: 1, md: 4 }} >
      <Text>Users Coming Soon!</Text>
    </Box>
  )
}