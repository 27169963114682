import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Box, Heading, Flex, Text, Button } from "@chakra-ui/core";

import MainStoreContext from "../stores/MainStoreContext";


const MenuItems = ({ children }) => (
    <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
        {children}
    </Text>
);

// Note: This code could be better, so I'd recommend you to understand how I solved and you could write yours better :)
const Header = props => {

    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);

    //f2e7d3

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="1rem"
            // bg="teal.500"
            bg="#272727"
            color="white"
            {...props}
        >
            <Flex align="center" mr={5}>
                {/* <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
                    Chakra UI
                </Heading> */}
                {props.leftIcon}
            </Flex>

            <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
                <svg
                    fill="white"
                    width="12px"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
            </Box>

            <Box
                display={{ xs: show ? "block" : "none", md: "flex" }}
                width={{ sm: "full", md: "auto" }}
                alignItems="center"
                flexGrow={1}
            >
                {props.children.map ? props.children.map((item, i) => {
                    return <MenuItems key={i}>{item}</MenuItems>;
                }) : <MenuItems >{props.children}</MenuItems>}
            </Box>

            <Box
                display={{ xs: show ? "block" : "none", md: "block" }}
                mt={{ base: 4, md: 0 }}
            >
                {/* <Button bg="transparent" border="1px" mr={5}>
                    Create account?
                </Button> */}
                {props.rightIcon}
            </Box>
        </Flex>
    );
};

export default Header;
